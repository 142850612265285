//SVG Positioning Setup
    $('.hiw-1,.hiw-2,.hiw-3,.hiw-4').velocity({ translateZ: 0, rotateY: "90deg" }, { duration: 0 });
    $(".icon").velocity({translateX: [ 50, 0 ], translateY: [50, 0], scaleX: 0.01, scaleY: 0.01 }, {duration:0});
    $(".house-window-object").velocity({ opacity: 0 });
 
//Displays
    function wfShowOuter(){
        $('.wf-left,.wf-right').show();
    }
    function wfHideOuter(){
        $('.wf-left, .wf-right').hide();
    }
    function hiwHideOuter(){
        $('.hiw-1,.hiw-2,.hiw-3,.hiw-4').hide();
    }
    function hiwShowOuter(){
        $('.hiw-1,.hiw-2,.hiw-3,.hiw-4').show();
    }

// Transitions
    // Title Transitions
        $.Velocity.RegisterEffect("transition.ttlIn", {
            defaultDuration: 750,
            calls: [ 
                [ { opacity: 1, y: [ "1", [ 0.550, 0.055, 0.850, 0.190 ] ] } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.ttlOut", {
            defaultDuration: 500,
            calls: [ 
                [ { opacity: [ 0, 25 ], y: [ "-20", [ 0.600, -0.180, 0.735, 0.045 ] ] } ]
            ],
            reset: { y: [ "-20", [ 0.600, -0.180, 0.735, 0.045 ] ] }
        });
    // Arched Text Transitions
        $.Velocity.RegisterEffect("transition.uprIn", {
            defaultDuration: 750,
            calls: [ 
                [ { opacity: 1, y: [ "13", [ 0.600, -0.280, 0.735, 0.045 ] ] } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.uprOut", {
            defaultDuration: 500,
            calls: [ 
                [ { opacity: [ 0, 25 ], y: [ "20", [ 0.600, -0.180, 0.735, 0.045 ] ] } ]
            ],
            reset: { y: [ "20", [ 0.600, -0.180, 0.735, 0.045 ] ] }
        });
    // Lower Text Transitions
        $.Velocity.RegisterEffect("transition.lwrIn", {
            defaultDuration: 750,
            calls: [ 
                [ { opacity: [ 1 ], y: [ "82", [ 0.550, 0.055, 0.850, 0.190 ] ] } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.lwrHomeIn", {
            defaultDuration: 750,
            calls: [ 
                [ { opacity: [ 1 ], y: [ "78.5", [ 0.550, 0.055, 0.850, 0.190 ] ] } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.lwrOut", {
            defaultDuration: 500,
            calls: [ 
                [ { opacity: [ 0, 25 ], y: [ "110", [ 0.600, -0.180, 0.735, 0.045 ] ] } ]
            ],
            reset: { y: [ "110", [ 0.600, -0.180, 0.735, 0.045 ] ] }
        });
    // Insect Transitions
        $.Velocity.RegisterEffect("transition.insectCrawl", {
            calls: [ 
                [ { y: [ "-550", [ 0.550, 0.055, 0.960, 0.175 ] ] } ]
            ],
            reset: { y: [ "0", [ 0.550, 0.055, 0.960, 0.175 ] ] }
        });
        $.Velocity.RegisterEffect("transition.insectFrameIn", {
            defaultDuration: 750,
            calls: [ 
                [ { y: [ "0", [ 0.600, -0.280, 0.735, 0.045 ] ] } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.insectFrameOut", {
            defaultDuration: 500,
            calls: [ 
                [ { y: [ "-170", [ 0.600, -0.180, 0.735, 0.045 ] ] } ]
            ],
            reset: { y: [ "-170", [ 0.600, -0.180, 0.735, 0.045 ] ] }
        });
    // Tent Transitions
        $.Velocity.RegisterEffect("transition.tentIn", {
            defaultDuration: 750,
            calls: [ 
                [ { y: [ "24.8", [ 0.390, 0.575, 0.565, 1.000 ] ] } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.tentOut", {
            defaultDuration: 500,
            calls: [ 
                [ { y: [ "-20", [ 0.600, -0.280, 0.735, 0.045 ] ] } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.houseSqueeze", {
            defaultDuration: 500,
            calls: [ 
                [ { scaleX: 0.85, scaleY: 0.85, x: 34, y:32} ]
            ]
        });
        $.Velocity.RegisterEffect("transition.houseUnSqueeze", {
            defaultDuration: 250,
            calls: [ 
                [ { y:37.5, scaleY: 0.85} ],
                [ { scaleX: 1.0, scaleY: 1.0, x: 26.5, y:25} ]
            ]
        });
    // Button Transitions
        $.Velocity.RegisterEffect("transition.buttonIn", {
            defaultDuration: 250,
            calls: [ 
                [ { y: [ "75", [ 0.550, 0.055, 0.850, 0.190 ] ] } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.buttonOut", {
            defaultDuration: 250,
            calls: [ 
                [ { y: [ "63.1", [ 0.230, 1.000, 0.320, 1.000 ] ] } ]
            ]
        });
    // Icon Transitions
         /////////// Apple
        $.Velocity.RegisterEffect("transition.iconAppleIn", {
            defaultDuration: 750,
            calls: [
                [ { translateX: [ "13.25", [ 0.550, 0.055, 0.850, 0.190 ] ], translateY: [ "22", [ 0.550, 0.055, 0.850, 0.190 ] ], scaleX: 1, scaleY: 1 } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.appleHover", {
            defaultDuration: 250,
            calls: [
                [ { translateX: 11, translateY: 21.5, scaleX: 1.2, scaleY: 1.2 } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.appleHoverEnd", {
            defaultDuration: 250,
            calls: [
                [ { translateX: 13.25, translateY:22, scaleX: 1.0, scaleY: 1.0 } ]
            ]
        });

        /////////// Plant
        $.Velocity.RegisterEffect("transition.iconPlantIn", {
            defaultDuration: 750,
            calls: [
                [ { translateX: [ "2.5", [ 0.550, 0.055, 0.850, 0.190 ] ], translateY: [ "51.05", [ 0.550, 0.055, 0.850, 0.190 ] ], scaleX: 1, scaleY: 1 } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.plantHover", {
            defaultDuration: 250,
            calls: [
                [ { translateX: 0.5, translateY: 49.5, scaleX: 1.2, scaleY: 1.2 } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.plantHoverEnd", {
            defaultDuration: 250,
            calls: [
                [ { translateX: 2.5, translateY: 51.05, scaleX: 1.0, scaleY: 1.0 } ]
            ]
        });

        /////////// Pets
        $.Velocity.RegisterEffect("transition.iconPetIn", {
            defaultDuration: 750,
            calls: [
                [ { translateX: [ "16.5", [ 0.550, 0.055, 0.850, 0.190 ] ], translateY: [ "73.25", [ 0.550, 0.055, 0.850, 0.190 ] ], scaleX: 1, scaleY: 1 } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.petHover", {
            defaultDuration: 250,
            calls: [
                [ { translateX: 13.5, translateY: 72.5, scaleX: 1.2, scaleY: 1.2 } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.petHoverEnd", {
            defaultDuration: 250,
            calls: [
                [ { translateX: 16.5, translateY: 73.25, scaleX: 1.0, scaleY: 1.0 } ]
            ]
        });

        /////////// Hotel
        $.Velocity.RegisterEffect("transition.iconHotelIn", {
            defaultDuration: 750,
            calls: [
                [ { translateX: [ "79.5", [ 0.550, 0.055, 0.850, 0.190 ] ], translateY: [ "22.5", [ 0.550, 0.055, 0.850, 0.190 ] ], scaleX: 1, scaleY: 1 } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.hotelHover", {
            defaultDuration: 250,
            calls: [
                [ { translateX: 79.5, translateY: 21.5, scaleX: 1.2, scaleY: 1.2 } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.hotelHoverEnd", {
            defaultDuration: 250,
            calls: [
                [ { translateX: 79.5, translateY: 22.5, scaleX: 1.0, scaleY: 1.0 } ]
            ]
        });

        /////////// Key
        $.Velocity.RegisterEffect("transition.iconKeyIn", {
            defaultDuration: 750,
            calls: [
                [ { translateX: [ "89.2", [ 0.550, 0.055, 0.850, 0.190 ] ], translateY: [ "51.35", [ 0.550, 0.055, 0.850, 0.190 ] ], scaleX: 1, scaleY: 1 } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.keyHover", {
            defaultDuration: 250,
            calls: [
                [ { translateX: 88.5, translateY: 50.5, scaleX: 1.2, scaleY: 1.2 } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.keyHoverEnd", {
            defaultDuration: 250,
            calls: [
                [ { translateX: 89.2, translateY: 51.35, scaleX: 1.0, scaleY: 1.0 } ]
            ]
        });
        /////////// Mail
        $.Velocity.RegisterEffect("transition.iconMailIn", {
            defaultDuration: 750,
            calls: [
                [ { translateX: [ "75.75", [ 0.550, 0.055, 0.850, 0.190 ] ], translateY: [ "73.35", [ 0.550, 0.055, 0.850, 0.190 ] ], scaleX: 1, scaleY: 1 } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.mailHover", {
            defaultDuration: 250,
            calls: [
                [ { translateX: 74.5, translateY: 72.5, scaleX: 1.2, scaleY: 1.2 } ]
            ]
        });
        $.Velocity.RegisterEffect("transition.mailHoverEnd", {
            defaultDuration: 250,
            calls: [
                [ { translateX: 75.75, translateY: 73.35, scaleX: 1.0, scaleY: 1.0 } ]
            ]
        });
        //Exit
        $.Velocity.RegisterEffect("transition.iconOut", {
            defaultDuration: 750,
            calls: [ 
                [ { translateX: [ "50", [ 0.600, -0.280, 0.735, 0.045 ] ], translateY: [ "50", [ 0.600, -0.280, 0.735, 0.045 ] ], scaleX: 0.6, scaleY: 0.6 } ]
            ]
        });

    //Hover Effects
        // How to Prepare
            // Global
                $.Velocity.RegisterEffect("transition.iconHover", {
                    defaultDuration: 250,
                    calls: [ 
                        [ { translateX: -1.5, translateY: -1.5, scaleX: 1.2, scaleY: 1.2 } ]
                    ]
                });
                $.Velocity.RegisterEffect("transition.iconHoverEnd", {
                    defaultDuration: 250,
                    calls: [ 
                        [ { translateX: 0, translateY: 0, scaleX: 1.0, scaleY: 1.0 } ]
                    ]
                });
            // Individual
                $.Velocity.RegisterEffect("transition.iconHover", {
                    defaultDuration: 250,
                    calls: [ 
                        [ { translateX: -1.5, translateY: -1.5, scaleX: 1.2, scaleY: 1.2 } ]
                    ]
                });
                $.Velocity.RegisterEffect("transition.iconHoverEnd", {
                    defaultDuration: 250,
                    calls: [ 
                        [ { translateX: 0, translateY: 0, scaleX: 1.0, scaleY: 1.0 } ]
                    ]
                });

// Functions
    // Enters & Exits
        // Global
            function objectExit(){
                $(".ttl").velocity("transition.ttlOut");
                $(".upr").velocity("transition.uprOut");
                $(".lwr").velocity("transition.lwrOut");
            }

        // Individual
            //Home Enter
                function homeEnter(){
                    $(".home .ttl").velocity("transition.ttlIn");
                    $(".home .upr").velocity("transition.uprIn");
                    $(".home .lwr").velocity("transition.lwrHomeIn");
                }

            //Home Exit
                function homeExit(){
                    objectExit();
                }

            //Why Fumigate Enter
                var interval0insect;
                var interval1insect;
                var interval2insect;
                var interval3insect;
                var interval4insect;
                var interval5insect;
                var interval6insect;
                var interval7insect;
                var interval8insect;
                var interval9insect; 
                // 0,1,2,4,6,8
                // 3,7,9,5
                function insectEnter(){
                    $(".insect0 image,.insect1 image,.insect2 image,.insect4 image,.insect6 image,.insect8 image").velocity("transition.insectCrawl", { delay: 0, duration: 2000 });
                    interval0insect = setInterval(function(){
                        $(".insect0 image,.insect1 image,.insect2 image,.insect4 image,.insect6 image,.insect8 image").velocity("transition.insectCrawl", { delay: 0, duration: 2000 });
                    }, 2050);   
                    
                }   
                function insectEnterTwo(){
                    $(".insect3 image,.insect5 image,.insect7 image,.insect9 image").velocity("transition.insectCrawl", { delay: 0, duration: 2000 }); 
                    interval3insect = setInterval(function(){
                        $(".insect3 image,.insect5 image,.insect7 image,.insect9 image").velocity("transition.insectCrawl", { delay: 0, duration: 2000 });
                    }, 2050);  
                    
                }  
                function whyFumigateShowOuter(){
                    wfShowOuter();
                    $('.wf-left').velocity({rotateY: "-90deg"}, {duration: 0});
                    $('.wf-right').velocity({rotateY: "-90deg", translateZ: 0,}, {duration: 0});
                    $('.wf-left,.wf-right').velocity({rotateY: "0deg" }, { duration: 500, easing: [0.175, 0.885, 0.320, 1.275]});
                }
                function whyFumigateEnter(){
                    $(".why-fumigate .ttl").velocity("transition.ttlIn");
                    $(".why-fumigate .upr").velocity("transition.uprIn");
                    $(".why-fumigate .lwr").velocity("transition.lwrIn");
                    $(".insectFrame image").velocity("transition.insectFrameIn");
                    setTimeout(whyFumigateShowOuter, 500);
                    $(".wf-ba").velocity("transition.buttonIn", { delay: 500 });
                    insectEnter();
                    setTimeout(insectEnterTwo, 400);
                }

            //Why Fumigate Exit
                function insectExit(){
                   clearInterval(interval0insect);
                   clearInterval(interval1insect);
                   clearInterval(interval2insect);
                   clearInterval(interval3insect);
                   clearInterval(interval4insect);
                   clearInterval(interval5insect);
                   clearInterval(interval6insect);
                   clearInterval(interval7insect);
                   clearInterval(interval8insect);
                   clearInterval(interval9insect);      
                } 

                function whyFumigateExit(){
                    insectExit();
                    objectExit();
                    $(".insectFrame image").velocity("transition.insectFrameOut");
                    $(".wf-ba").velocity("transition.buttonOut");
                    $('.wf-left').velocity({rotateY: "-90deg" }, { duration: 500 });
                    $('.wf-right').velocity({rotateY: "90deg" }, { duration: 500 });
                    setTimeout(wfHideOuter, 505);
                }

            //How it Works Enter
                function addTent(){
                    $(".house-object-tent").velocity("transition.tentIn");
                    if (howItWorksTent.attr("class") == "house-object-tent temp-hide velocity-animating") {
                        // Do Nothing
                    } else{
                        $(".house-object").velocity("transition.houseSqueeze", { delay: 300});
                    }
                }

                function removeTent(){
                    $(".house-object-tent").velocity("transition.tentOut");
                    if (howItWorksTent.attr("class") == "house-object-tent temp-hide velocity-animating") {
                        // Do nothing
                    } else{
                        $(".house-object").velocity("transition.houseUnSqueeze");
                        $(".house-window-object").velocity("transition.houseUnSqueeze");
                    }
                }


                function howItWorksShowOuter(){
                    hiwShowOuter();
                    $('.hiw-1,.hiw-2,.hiw-3,.hiw-4').velocity({translateZ: 0, rotateY: "0deg" }, { duration: 500, easing: [0.175, 0.885, 0.320, 1.275]});
                }

                function howItWorksEnter(){
                    $(".how-it-works .ttl").velocity("transition.ttlIn");
                    $(".how-it-works .upr").velocity("transition.uprIn");
                    $(".how-it-works .lwr").velocity("transition.lwrIn");
                    setTimeout(howItWorksShowOuter, 1250);
                    setTimeout(addTent, 600);
                }

            //How it Works Exit
                function howItWorksExit(){
                    objectExit();
                    $('.hiw-1,.hiw-2,.hiw-3,.hiw-4').velocity({rotateY: "90deg" }, { duration: 500 });
                
                    setTimeout(hiwHideOuter, 505);
                    removeTent();
                }

            //How to Prepare Enter
                function howtoPrepareShowWindows(){
                    $(".house-window-object").velocity({ opacity: 1});
                }
                function howToPrepareEnter(){
                    setTimeout(howtoPrepareShowWindows, 750);
                    $(".how-to-prepare .ttl").velocity("transition.ttlIn");
                    $(".how-to-prepare .upr").velocity("transition.uprIn");
                    $(".how-to-prepare .lwr").velocity("transition.lwrIn");
                    $(".apple").velocity("transition.iconAppleIn");
                    $(".plant").velocity("transition.iconPlantIn");
                    $(".pet").velocity("transition.iconPetIn");
                    $(".key").velocity("transition.iconKeyIn");
                    $(".mail").velocity("transition.iconMailIn");
                    $(".hotel").velocity("transition.iconHotelIn");
                }

            //How to prepare Hovers
                //How to prepare Hovers
                $(".hotel.icon circle").hover(function(){
                    $('.hotel.icon').stop().velocity("transition.hotelHover");
                }, function() {
                    $('.hotel.icon').stop().velocity("transition.hotelHoverEnd");
                });

                $(".key.icon circle").hover(function(){
                    $('.key.icon').stop().velocity("transition.keyHover");
                }, function() {
                    $('.key.icon').stop().velocity("transition.keyHoverEnd");
                });

                $(".mail.icon circle").hover(function(){
                    $('.mail.icon').stop().velocity("transition.mailHover");
                }, function() {
                    $('.mail.icon').stop().velocity("transition.mailHoverEnd");
                });

                $(".pet.icon circle").hover(function(){
                    $('.pet.icon').stop().velocity("transition.petHover");
                }, function() {
                    $('.pet.icon').stop().velocity("transition.petHoverEnd");
                });

                $(".plant.icon circle").hover(function(){
                    $('.plant.icon').stop().velocity("transition.plantHover");
                }, function() {
                    $('.plant.icon').stop().velocity("transition.plantHoverEnd");
                });

                $(".apple.icon circle").hover(function(){
                    $('.apple.icon').stop().velocity("transition.appleHover");
                }, function() {
                    $('.apple.icon').stop().velocity("transition.appleHoverEnd");
                });

            //How to Prepare Clicks
                $(".icon circle").click(function() {
					
                    if($(this).siblings(".the-x").css('opacity') === "0" || $(this).siblings('.the-art').css('opacity') === "1"){
                        $(this).siblings('.the-art').css({ opacity: 0 });
                        $(this).siblings('.the-x').css({ opacity: 1 });
                        $(".icon circle").not(this).siblings('.the-x').css({ opacity: 0 });
                        $(".icon circle").not(this).siblings('.the-art').css({ opacity: 1 });
                    } else{      
                        $(this).siblings('.the-art').css({ opacity: 1 });
                        $(this).siblings('.the-x').css({ opacity: 0 });       
                       $(".the-art").each( function () {
                            if($(this).css('opacity') === "0" || $(this).siblings('.the-x').css('opacity') === "1"){      
                                $(this).css({ opacity: 1 });
                                $(this).siblings('.the-x').css({ opacity: 0 });
                            } 
                        });  
                    } 

            //Content Conditions
                    $('.bubble').hide();
                    $('.hotel-txt').hide();
                    //plant
                    if ($(this).parent('.plant').length > 0){
                        showHideFunction($(this), '.plant-txt');
                    } 

					if ($(this).parent('.hotel').length > 0){
                        showHideFunction($(this), '.hotel-txt');
                    } 

					if ($(this).parent('.mail').length > 0){
                        showHideFunction($(this), '.mail-txt');
                    }

					if ($(this).parent('.apple').length > 0){
                        showHideFunction($(this), '.apple-txt');
                    }

					if ($(this).parent('.key').length > 0){
                        showHideFunction($(this), '.key-txt');
                    }

					if ($(this).parent('.pet').length > 0){
                        showHideFunction($(this), '.pets-txt');
                    }

  					function showHideFunction(clicked,item) {
						if (clicked.siblings(".the-x").css('opacity') === "1"){
							$('.how-to-prepare-circle').show();
			                $(item).show();
                        } else{
							$('.how-to-prepare-circle').hide();
			                $(item).hide();
                        }
						trackEvent('Hotel Button', 'click', 'User revealed How To Prepare: ' + item);
					}
            });

            //How to Prepare Exit
                function howToPrepareExit(){
                    objectExit();
                    $(".icon").velocity("transition.iconOut");
                    $(".house-window-object").velocity({ opacity: 0});
                    $('.how-to-prepare-circle').hide();
                    $('.bubble').hide();
                    $('.hotel-button').hide();
                    $('.the-art').css({ opacity: 1 });
                    $('.the-x').css({ opacity: 0 });
                }