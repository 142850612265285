/////////////////// Variables ///////////////////
// Selectors
// Navigation
var menuIcon = $('.menu-icon');
var menuToggle = $('.menu-toggle');
var menu = $('.off-canvas');
var downArrow = $('.down-arrow-container');
var brand = $('.brand-home');
// SVG
var allSVG = $('svg *');
var theSVG = $('.the-svg');
// Fullpage
var fullPage = $('.fullpage');
var hero = $('.hero');
// Video Object
var videoObject = $('.video object');
// Panels
var whyFumigateChild = $('.why-fumigate-child');
var howItWorksChild = $('.how-it-works-child');
var howToPrepareChild = $('.how-to-prepare-child');
// Panel Specific
var revealSlider = $('.reveal-slider');
var howItWorksHeroAll = $('g.how-it-works');
var howItWorksTent = $('.house-object-tent');
var howItWorksSpinPanelA = $('.the-fumigation-process .spin-container');
var whyFumigateSpinPanelA = $('.spot-treatment-unit .spin-container');
var whyFumigateHeroAll = $('g.why-fumigate');
var whyFumigateButton = $('.why-fumigate-button');
var howToPrepareHeroAll = $('g.how-to-prepare');
var howToPrepareHotelButton = $('.hotel-button');
var howToPrepareContent = $('.how-to-prepare-icons');
var howToPrepareWindows = $('.house-window-object');
var howToPrepareSpinPanelA = $('#section8 .spin-container');
// Backgrounds
var yPanel = $('.yellow-panel');
var bPanel = $('.blue-panel');
var pPanel = $('.purple-panel');
var oPanel = $('.orange-panel');

/////////////////// Global Events ///////////////////
//Close Menu
function closeMenu() {
  menu.removeClass('active');
  menuIcon.removeClass('active');
}
// Open Menu
function openMenu() {
  menu.addClass('active');
  menuIcon.addClass('active');
}
// Toggle Menu
function toggleMenu() {
  menu.toggleClass('active');
  menuIcon.toggleClass('active');
}
// Hide Nav Arrow
function removeArrow() {
  downArrow.addClass('inactive');
  downArrow.removeClass('bounce');
}
// Force Animation Immediate Point B
function finishAnimation() {
  allSVG.velocity('finish');
}
// Toggle Clickable Hero Panels
function clickable() {
  fullPage.addClass('clickable');
}
function unclickable() {
  fullPage.removeClass('clickable');
}
// Parallax Init
function parallaxInit() {
  parallax({
    element: document.querySelector('#parallax'),
    distance: 0,
    reverse: true
  });
  parallax({
    element: document.querySelector('.the-svg'),
    distance: 0
  });
}

// Usability Disallow Scrolling
function allowScroll() {
  if ($('html').hasClass('no-touch')) {
    $.fn.fullpage.setAllowScrolling(true);
  }
}
function disallowScroll() {
  if ($('html').hasClass('no-touch')) {
    $.fn.fullpage.setAllowScrolling(false);
  }
}
function scrollSetting() {
  if ($('html').hasClass('no-touch')) {
    disallowScroll();
    setTimeout(allowScroll, 2050);
  }
}
function scrollSettingQuick() {
  if ($('html').hasClass('no-touch')) {
    disallowScroll();
    setTimeout(allowScroll, 2050);
  }
}

// Usability Managing Swipe Events on Tablet
var touchSupport = true;
function touchFullPageUp() {
  $.fn.fullpage.moveSectionUp();
}
function touchFullPageDown() {
  $.fn.fullpage.moveSectionDown();
}
function disableTouch() {
  touchSupport = false;
}
function enableTouch() {
  touchSupport = true;
}

function touchScrollUp() {
  if (touchSupport == true) {
    touchFullPageUp();
    touchSupport = false;
    setTimeout(enableTouch, 2050);
  }
}

function touchScrollDown() {
  if (touchSupport == true) {
    touchFullPageDown();
    touchSupport = false;
    setTimeout(enableTouch, 2050);
  }
}

/////////////////// Panel Specific Events ///////////////////
// How it Works
// Show Reveal Slider
function moveRevealSliderBack() {
  $('.reveal-slider-container').insertAfter('.wf-after');
}
function showRevealSlider() {
  $('.reveal-slider-container').insertAfter('.fullpage');
  revealSlider.velocity('fadeIn', { duration: 300 });
  //$.fn.fullpage.setAllowScrolling(false);
}
// Hide Reveal Slider
function hideRevealSlider() {
  if ($(window).width() > 767) {
    revealSlider.velocity('fadeOut', { duration: 300 });
    setTimeout(moveRevealSliderBack, 300);
  }
  //$.fn.fullpage.setAllowScrolling(true);
}

// Hide All 'How it Works' Hero Content, used for back-to-top functions
function hideHowItWorksHeroDisplay() {
  howItWorksHeroAll.attr('class', 'how-it-works temp-hide');
  howItWorksTent.attr('class', 'house-object-tent temp-hide');
}

// Reset Display of 'How it Works' Hero Content with Default 'visible', used for back-to-top functions
function resetHowItWorksHeroDisplay() {
  howItWorksHeroAll.attr('class', 'how-it-works');
  howItWorksTent.attr('class', 'house-object-tent');
}

// Hide All 'Why Fumigate' Hero Content, used for back-to-top functions
function hideWhyFumigateHeroDisplay() {
  whyFumigateHeroAll.attr('class', 'why-fumigate temp-hide');
  whyFumigateButton.attr('class', 'why-fumigate-button temp-hide');
}

// Reset Display of 'Why Fumigate' Hero Content with Default 'visible', used for back-to-top functions
function resetWhyFumigateHeroDisplay() {
  whyFumigateHeroAll.attr('class', 'why-fumigate');
  whyFumigateButton.attr('class', 'why-fumigate-button');
}

// Hide All 'How to Prepare' Hero Content, used for back-to-top functions
function hideHowToPrepareHeroDisplay() {
  howToPrepareHeroAll.attr('class', 'how-to-prepare temp-hide');
  howToPrepareHotelButton.attr('class', 'hotel-button temp-hide');
  howToPrepareContent.attr('class', 'how-to-prepare-icons temp-hide');
  howToPrepareWindows.attr('class', 'house-window-object temp-hide');
}

// Reset Display of 'How to Prepare' Hero Content with Default 'visible', used for back-to-top functions
function resetHowToPrepareHeroDisplay() {
  howToPrepareHeroAll.attr('class', 'how-to-prepare');
  howToPrepareHotelButton.attr('class', 'hotel-button');
  howToPrepareContent.attr('class', 'how-to-prepare-icons');
  howToPrepareWindows.attr('class', 'house-window-object');
}

function hoverBubbleIn() {
  $('img.hover-compare').addClass('activeCompare');
}
function hoverBubbleOut() {
  $('img.hover-compare').removeClass('activeCompare');
}
// Spin Panels
// How It Works
function howItWorksSpinA() {
  howItWorksSpinPanelA.addClass('active');
}
function howToPrepareSpinA() {
  howToPrepareSpinPanelA.addClass('active');
}
function whyFumigateSpinA() {
  whyFumigateSpinPanelA.addClass('active');
}

function animateFinalInsects() {
  $('.termites-panel').addClass('active-termites');
}

function unAnimateFinalInsects() {
  $('.termites-panel').removeClass('active-termites');
}

//Pause Brightcove player

/////////////////// Background Events ///////////////////
// SlideLeaves

// Yellow / Orange
function yellowToOrange() {
  yPanel.addClass('top');
  yPanel.removeClass('active');
  oPanel.addClass('active');
}
function orangeToYellow() {
  yPanel.addClass('active');
  yPanel.removeClass('top');
  oPanel.removeClass('active');
}
// Yellow / Purple
function yellowToPurple() {
  yPanel.addClass('top');
  yPanel.removeClass('active');
  pPanel.addClass('active');
}
function purpleToYellow() {
  yPanel.addClass('active');
  yPanel.removeClass('top');
  pPanel.removeClass('active');
}
// Yellow / Blue
function yellowToBlue() {
  yPanel.addClass('top');
  yPanel.removeClass('active');
  bPanel.addClass('active');
  bPanel.removeClass('top');
}
function blueToYellow() {
  yPanel.addClass('active');
  yPanel.removeClass('top');
  bPanel.removeClass('active');
}
// Blue / Orange
function blueToOrange() {
  bPanel.addClass('top');
  bPanel.removeClass('active');
  oPanel.addClass('active');
}
function orangeToBlue() {
  bPanel.addClass('active');
  bPanel.removeClass('top');
  oPanel.removeClass('active');
}
// Blue / Purple
function blueToPurple() {
  bPanel.addClass('top');
  bPanel.removeClass('active');
  pPanel.addClass('active');
}
function purpleToBlue() {
  bPanel.addClass('active');
  bPanel.removeClass('top');
  pPanel.removeClass('active');
}
// Purple / Orange
function purpleToOrange() {
  pPanel.addClass('top');
  pPanel.removeClass('active');
  oPanel.addClass('active');
}
function orangeToPurple() {
  pPanel.addClass('active');
  pPanel.removeClass('top');
  oPanel.removeClass('active');
}

/////////////////// Menu Events ///////////////////
// Movements
function silent1() {
  $.fn.fullpage.silentMoveTo(1);
}
function silent2() {
  $.fn.fullpage.silentMoveTo(2);
}
function silent5() {
  $.fn.fullpage.silentMoveTo(5);
}
function silent8() {
  $.fn.fullpage.silentMoveTo(8);
}
function scroll2() {
  $.fn.fullpage.moveTo(2);
}
function scroll5() {
  $.fn.fullpage.moveTo(5);
}
function scroll8() {
  $.fn.fullpage.moveTo(8);
}
function scroll1() {
  $.fn.fullpage.moveTo(1);
}

// Events
function navBackToTop() {
  if (screen.width > 767) {
    finishAnimation();
    closeMenu();
    if (hero.hasClass('active')) {
      silent1();
    } else if (howToPrepareChild.hasClass('active')) {
      hideHowToPrepareHeroDisplay();
      scroll8();
      finishAnimation();
      setTimeout(silent1, 900);
      setTimeout(resetHowToPrepareHeroDisplay, 2000);
    } else if (howItWorksChild.hasClass('active')) {
      hideHowItWorksHeroDisplay();
      scroll5();
      finishAnimation();
      setTimeout(silent1, 900);
      setTimeout(resetHowItWorksHeroDisplay, 2000);
    } else if (whyFumigateChild.hasClass('active')) {
      hideWhyFumigateHeroDisplay();
      scroll2();
      finishAnimation();
      setTimeout(silent1, 900);
      setTimeout(resetWhyFumigateHeroDisplay, 2000);
    }
  } else {
    scroll1();
  }
}
function navWhyFumigate() {
  closeMenu();
  clickable();
  if (hero.hasClass('active')) {
    silent2();
  } else if (howToPrepareChild.hasClass('active')) {
    hideHowToPrepareHeroDisplay();
    scroll8();
    finishAnimation();
    setTimeout(silent2, 900);
    setTimeout(resetHowToPrepareHeroDisplay, 2000);
  } else if (howItWorksChild.hasClass('active')) {
    hideHowItWorksHeroDisplay();
    scroll5();
    finishAnimation();
    setTimeout(silent2, 900);
    setTimeout(resetHowItWorksHeroDisplay, 2000);
  } else if (whyFumigateChild.hasClass('active')) {
    scroll2();
  }
}
function navHowItWorks() {
  closeMenu();
  if (hero.hasClass('active')) {
    silent5();
  } else if (howToPrepareChild.hasClass('active')) {
    hideHowToPrepareHeroDisplay();
    scroll8();
    finishAnimation();
    setTimeout(silent5, 900);
    setTimeout(resetHowToPrepareHeroDisplay, 2000);
  } else if (howItWorksChild.hasClass('active')) {
    scroll5();
  } else if (whyFumigateChild.hasClass('active')) {
    scroll5();
  }
}
function navHowToPrepare() {
  closeMenu();
  clickable();
  if (hero.hasClass('active')) {
    $.fn.fullpage.silentMoveTo(8);
  } else if (howToPrepareChild.hasClass('active')) {
    scroll8();
  } else if (howItWorksChild.hasClass('active')) {
    howItWorksExit();
    scroll8();
    finishAnimation();
  } else if (whyFumigateChild.hasClass('active')) {
    hideHowItWorksHeroDisplay();
    scroll5();
    finishAnimation();
    setTimeout(silent8, 900);
    setTimeout(resetHowItWorksHeroDisplay, 2000);
  }
}

function reActviatePlayerA() {
  $('#section3 object').each(function() {
    var $th = $(this);
    $th
      .attr({
        data: $th.attr('dataReset')
      })
      .removeAttr('dataReset');
  });
  $('#section3 iframe').each(function() {
    var $thifn = $(this);
    $thifn
      .attr({
        src: $thifn.attr('srcTemp')
      })
      .removeAttr('srcTemp');
  });
}

function playerPauseA() {
  $('#section3 object').each(function() {
    var $t = $(this);
    $t
      .attr({
        dataReset: $t.attr('data')
      })
      .removeAttr('data');
  });

  $('#section3 iframe').each(function() {
    var $thif = $(this);
    $thif
      .attr({
        srcTemp: $thif.attr('src')
      })
      .removeAttr('src');
  });
  setTimeout(reActviatePlayerA, 10);
}

function reActviatePlayerB() {
  $('#section6 object').each(function() {
    var $th = $(this);
    $th
      .attr({
        data: $th.attr('dataReset')
      })
      .removeAttr('dataReset');
  });
  $('#section6 iframe').each(function() {
    var $thifn = $(this);
    $thifn
      .attr({
        src: $thifn.attr('srcTemp')
      })
      .removeAttr('srcTemp');
  });
}

function playerPauseB() {
  $('#section6 object').each(function() {
    var $t = $(this);
    $t
      .attr({
        dataReset: $t.attr('data')
      })
      .removeAttr('data');
  });
  $('#section6 iframe').each(function() {
    var $t = $(this);
    $t
      .attr({
        srcTemp: $t.attr('src')
      })
      .removeAttr('src');
  });
  setTimeout(reActviatePlayerB, 10);
}

function fastHideRemove() {
  $('.the-svg').css({ 'z-index': '0' });
}

function fastHide() {
  $('.the-svg').css({ 'z-index': '-1' });
  setTimeout(fastHideRemove, 1000);
}

function trackEvent(interactionObject, actionType, label) {
  if (window.ga) {
    //console.log('track event');
    window.ga('send', 'event', interactionObject, actionType, label);
  }
}
