/// <reference path="./functions.js" />

//see https://github.com/alvarotrigo/fullPage.js#options
function fullpageConfig() {
  var slides = $('.section'),
    slideCount = slides.length,
    lastIndex = slideCount - 1,
    $downArrowContainer = $('.down-arrow-container'),
    $downArrow = $('#down-arrow'),
    isMobileWidth = $(document).width() < 750;

  $('#fullpage').fullpage({
    autoScrolling: true,
    scrollingSpeed: 800,
    keyboardScrolling: true,
    fitToSection: false,
    verticalCentered: true,

    //scrollBar: true,

    navigation: true,
    navigationPosition: 'right',

    easing: 'easeInCubic',
    sectionsColor: ['transparent', 'transparent', '#e9eaeb', '#e9eaeb', 'transparent', '#e9eaeb', '#e9eaeb', 'transparent', '#e9eaeb', '#e9eaeb', '#e9eaeb'],
    responsiveWidth: 767,
    css3: true,
    easingcss3: 'cubic-bezier(1.000, 0.020, 0.250, 1.000)',
    onLeave: function(index, nextIndex, direction) {
      //console.log('onLeave - index: ' + index + ', next:' + nextIndex + ', direction: ' + direction + ', last: ' + lastIndex);
      if (!isMobileWidth) {
        $downArrowContainer.toggle(nextIndex <= lastIndex).removeClass('bounce');

        // //change color of down arrow's polygon (svg) on certain pages
        // if (index <= 1 || index === 4 || index === 7) {
        //   $downArrow.attr('fill', '#fff');
        // } else {
        //   $downArrow.attr('fill', '#518ba2');
        // }
      }

      ////////////////////////////// Leaving Home /////////////////////////////////
      // Singular
      // Why Fumigate [Hero] (ScrollDown, Menu)
      if (index == 1 && nextIndex == 2) {
        if (screen.width > 767) {
          finishAnimation();
          //removeArrow();
          homeExit();
          setTimeout(whyFumigateEnter, 900);
          yellowToBlue();
          scrollSetting();
          clickable();
        }
      } else if (index == 1 && nextIndex == 5) {
        // How it Works [Hero] (Menu)
        if (screen.width > 767) {
          finishAnimation();
          //removeArrow();
          homeExit();
          setTimeout(howItWorksEnter, 900);
          yellowToPurple();
          scrollSetting();
        }
      } else if (index == 1 && nextIndex == 8) {
        // How to Prepare [Hero] (Menu)
        if (screen.width > 767) {
          finishAnimation();
          //removeArrow();
          homeExit();
          setTimeout(howToPrepareEnter, 900);
          yellowToOrange();
          scrollSetting();
          clickable();
        }
      } else if (index == 2 && nextIndex == 1) {
        ////////////////////////////// Leaving Why Fumigate /////////////////////////////////
        // Why Fumigate
        // Home [Hero] (ScrollUp, Menu)
        if (screen.width > 767) {
          unclickable();
          finishAnimation();
          whyFumigateExit();
          setTimeout(homeEnter, 900);
          blueToYellow();
          scrollSetting();
          hideRevealSlider();
        }
      } else if (index == 2 && nextIndex == 3) {
        // Why Fumigate B [Child] (ScrollDown)
        if (screen.width > 767) {
          unclickable();
          finishAnimation();
          whyFumigateExit();
          scrollSettingQuick();
          //setTimeout(finishAnimation, 750);
          hoverBubbleIn();
          hideRevealSlider();
          whyFumigateSpinA();
        }
      } else if (index == 2 && nextIndex == 5) {
        // How it Works [Hero] (Menu)
        if (screen.width > 767) {
          finishAnimation();
          whyFumigateExit();
          setTimeout(howItWorksEnter, 900);
          blueToPurple();
          scrollSetting();
          unclickable();
          hideRevealSlider();
        }
      } else if (index == 2 && nextIndex == 8) {
        // How to Prepare [Hero] (Menu)
        if (screen.width > 767) {
          finishAnimation();
          whyFumigateExit();
          setTimeout(howToPrepareEnter, 900);
          blueToOrange();
          scrollSetting();
          hideRevealSlider();
        }
      } else if (index == 3 && nextIndex == 1) {
        // Why Fumigate Panel B
        // Home [Hero] (Menu)
        //Does Not Exist
      } else if (index == 3 && nextIndex == 2) {
        // Why Fumigate [Hero] (ScrollUp, Menu)
        if (screen.width > 767) {
          finishAnimation();
          whyFumigateEnter();
          scrollSetting();
          clickable();
          hoverBubbleOut();
        }
      } else if (index == 3 && nextIndex == 4) {
        // Why Fumigate Panel C [Child] (ScrollDown)
        if (screen.width > 767) {
          scrollSettingQuick();
          hoverBubbleOut();
        }
      } else if (index == 3 && nextIndex == 5) {
        // How it Works [Hero] (Menu)
        if (screen.width > 767) {
          finishAnimation();
          //whyFumigateExit();
          //finishAnimation();
          howItWorksEnter();
          scrollSetting();
          blueToPurple();
          hoverBubbleOut();
        }
      } else if (index == 3 && nextIndex == 8) {
        // How to Prepare [Hero] (Menu)
        //Does Not Exist
      } else if (index == 4 && nextIndex == 1) {
        // Why Fumigate Panel C
        // Home [Hero] (Menu)
        //Does Not Exist
      } else if (index == 4 && nextIndex == 2) {
        // Why Fumigate [Hero] (Menu)
        if (screen.width > 767) {
          finishAnimation();
          whyFumigateEnter();
          whyFumigateSpinA();
          scrollSetting();
          clickable();
          playerPauseA();
        }
      } else if (index == 4 && nextIndex == 3) {
        // Why Fumigate Panel B [Child] (ScrollUp)
        if (screen.width > 767) {
          scrollSettingQuick();
          hoverBubbleIn();
          whyFumigateSpinA();
          playerPauseA();
        }
        // There is nothing to do.
      } else if (index == 4 && nextIndex == 5) {
        // How it Works [Hero] (ScrollDown, Menu)
        if (screen.width > 767) {
          finishAnimation();
          //whyFumigateExit();
          //finishAnimation();
          howItWorksEnter();
          blueToPurple();
          scrollSetting();
          playerPauseA();
        }
      } else if (index == 4 && nextIndex == 8) {
        // How to Prepare [Hero] (Menu)
        //Does Not Exist
      } else if (index == 5 && nextIndex == 1) {
        ////////////////////////////// Leaving How it Works /////////////////////////////////
        // How it Works
        // Home [Hero] (Menu)
        if (screen.width > 767) {
          finishAnimation();
          howItWorksExit();
          setTimeout(homeEnter, 900);
          purpleToYellow();
          scrollSetting();
        }
      } else if (index == 5 && nextIndex == 4) {
        // Why Fumigate C [Child] (ScrollUp)
        if (screen.width > 767) {
          finishAnimation();
          howItWorksExit();
          scrollSettingQuick();
          purpleToBlue();
        }

        //setTimeout(whyFumigateEnter, 1001);
      } else if (index == 5 && nextIndex == 6) {
        // How it Works B [Child] (ScrollDown)
        if (screen.width > 767) {
          howItWorksSpinA();
          finishAnimation();
          howItWorksExit();
          scrollSettingQuick();
        }
      } else if (index == 5 && nextIndex == 2) {
        // Why Fumigate [Hero] (Menu)
        if (screen.width > 767) {
          finishAnimation();
          howItWorksExit();
          setTimeout(whyFumigateEnter, 900);
          purpleToBlue();
          scrollSetting();
          clickable();
        }
      } else if (index == 5 && nextIndex == 8) {
        // How to Prepare [Hero] (Menu)
        if (screen.width > 767) {
          finishAnimation();
          howItWorksExit();
          setTimeout(howToPrepareEnter, 900);
          purpleToOrange();
          scrollSetting();
          clickable();
        }
      } else if (index == 6 && nextIndex == 1) {
        // How it Works Panel B
        // Home [Hero] (Menu)
        //Does Not Exist
      } else if (index == 6 && nextIndex == 5) {
        // How it Works [Hero] (ScrollUp, Menu)
        if (screen.width > 767) {
          finishAnimation();
          howItWorksEnter();
          scrollSetting();
        }
      } else if (index == 6 && nextIndex == 7) {
        // How it Works Panel C [Child] (ScrollDown)
        if (screen.width > 767) {
          scrollSettingQuick();
          howItWorksSpinA();
          // Nothing to do.
        }
      } else if (index == 6 && nextIndex == 2) {
        // Why Fumigate [Hero] (Menu)
        // Does not exist.
      } else if (index == 6 && nextIndex == 8) {
        // How to Prepare [Hero] (Menu)
        if (screen.width > 767) {
          finishAnimation();
          howToPrepareEnter();
          scrollSetting();
          purpleToOrange();
          clickable();
        }
      } else if (index == 7 && nextIndex == 1) {
        // How it Works Panel C
        // Home [Hero] (Menu)
        //Does Not Exist
      } else if (index == 7 && nextIndex == 2) {
        // Why Fumigate [Hero] (Menu)
        //Does Not Exist
      } else if (index == 7 && nextIndex == 6) {
        // How it Works Panel B [Child] (ScrollUp)
        if (screen.width > 767) {
          scrollSettingQuick();
          howItWorksSpinA();
          playerPauseB();
        }
      } else if (index == 7 && nextIndex == 8) {
        // How to Prepare [Hero] (ScrollDown, Menu)
        if (screen.width > 767) {
          finishAnimation();
          howToPrepareEnter();
          scrollSetting();
          purpleToOrange();
          clickable();
          playerPauseB();
        }
      } else if (index == 7 && nextIndex == 5) {
        // How it Works [Hero] (Menu)
        if (screen.width > 767) {
          finishAnimation();
          howItWorksEnter();
          scrollSetting();
          playerPauseB();
        }
      } else if (index == 8 && nextIndex == 1) {
        ////////////////////////////// Leaving How to Prepare /////////////////////////////////
        // How to Prepare
        // Home [Hero] (Menu)
        if (screen.width > 767) {
          finishAnimation();
          howToPrepareExit();
          setTimeout(homeEnter, 900);
          scrollSetting();
          orangeToYellow();
          unclickable();
        }
      } else if (index == 8 && nextIndex == 7) {
        // How it Works C [Child] (ScrollUp)
        fastHide();
        unclickable();
        if (screen.width > 767) {
          finishAnimation();
          howToPrepareExit();
          scrollSettingQuick();
          orangeToPurple();
        }
      } else if (index == 8 && nextIndex == 9) {
        // How to Prepare B [Child] (ScrollDown)
        fastHide();
        unclickable();
        if (screen.width > 767) {
          howToPrepareSpinA();
          finishAnimation();
          howToPrepareExit();
          scrollSettingQuick();
        }
      } else if (index == 8 && nextIndex == 2) {
        // Why Fumigate [Hero] (Menu)
        if (screen.width > 767) {
          finishAnimation();
          howToPrepareExit();
          setTimeout(whyFumigateEnter, 900);
          orangeToBlue();
          scrollSetting();
        }
      } else if (index == 8 && nextIndex == 5) {
        // How it Works [Hero] (Menu)
        if (screen.width > 767) {
          finishAnimation();
          howToPrepareExit();
          setTimeout(howItWorksEnter, 900);
          orangeToPurple();
          scrollSetting();
          unclickable();
        }
      } else if (index == 10 && nextIndex == 1) {
        // How to Prepare Panel B
        // Home [Hero] (Menu)
        //Does Not Exist
      } else if (index == 9 && nextIndex == 8) {
        // How to Prepare Hero [Hero] (ScrollUp, Menu)
        if (screen.width > 767) {
          finishAnimation();
          howToPrepareEnter();
          scrollSetting();
          clickable();
        }
      } else if (index == 10 && nextIndex == 11) {
        // How to Prepare Panel C [Child] (ScrollDown)
        //console.log('Animate them buggos!');
        if (screen.width > 767) {
          scrollSettingQuick();
          animateFinalInsects();
          howToPrepareSpinA();
        }
      } else if (index == 9 && nextIndex == 2) {
        // Why Fumigate [Hero] (Menu)
        //Does Not Exist
      } else if (index == 9 && nextIndex == 5) {
        // How it Works [Hero] (Menu)
        //Does Not Exist
      } else if (index == 10 && nextIndex == 1) {
        // How it Works Panel C
        // Home [Hero] (Menu)
        //Does Not Exist
      } else if (index == 10 && nextIndex == 2) {
        // Why Fumigate [Hero] (Menu)
        //Does Not Exist
      } else if (index == 10 && nextIndex == 11) {
        // How to Prepare Panel B [Child] (ScrollUp)
        if (screen.width > 767) {
          scrollSettingQuick();
          unAnimateFinalInsects();
        }
      } else if (index == 10 && nextIndex == 8) {
        // How to Prepare [Hero] (Menu)
        if (screen.width > 767) {
          finishAnimation();
          howToPrepareEnter();
          scrollSetting();
          clickable();
          unAnimateFinalInsects();
        }
      } else if (index == 10 && nextIndex == 5) {
        // How it Works [Hero] (Menu)
        //Does Not Exist
      }
    }, //End OnLeave
    afterLoad: function(anchorLink, index) {
      //console.log('afterLoad - index: ' + index);

      //hide downarrow on last section
      if (!isMobileWidth) {
        //change color of down arrow's polygon (svg) on certain pages
        if (index <= 2 || index === 5 || index === 8) {
          $downArrow.attr('fill', '#fff');
        } else {
          $downArrow.attr('fill', '#518ba2');
        }

        // if (index <= lastIndex) {
        //   $downArrowContainer.fadeIn();
        // }
      }
    },

    afterRender: function() {
      setTimeout(function() {
        $downArrowContainer.addClass('bounce');
      }, 1200);

      $('#fp-nav').css('margin-top', '');
      //disable the dot navigation clicks
      $('#fp-nav a').on('click', function(e) {
        $(this).attr('disabled', true);
        e.preventDefault();
        return false;
      });
    }
  }); // End fullpage plugin Init

  $downArrowContainer.on('click', function() {
    $.fn.fullpage.moveSectionDown();

    //analytics
    trackEvent('down-arrow-button', 'click', 'Moved to next section');
  });
} // End fullPageConfig
