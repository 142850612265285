;( function( window ) {
	
	'use strict';

	function PathLoader( el ) {
		this.el = el;
		// clear stroke
		this.el.style.strokeDasharray = this.el.style.strokeDashoffset = this.el.getTotalLength();
	}

	PathLoader.prototype._draw = function( val ) {
		this.el.style.strokeDashoffset = this.el.getTotalLength() * ( 1 - val );
	}

	PathLoader.prototype.setProgress = function( val, callback ) {
		this._draw(val);
		if( callback && typeof callback === 'function' ) {
			// give it a time (ideally the same like the transition time) so that the last progress increment animation is still visible.
			setTimeout( callback, 200 );
		}
	}

	PathLoader.prototype.setProgressFn = function( fn ) {
		if( typeof fn === 'function' ) { fn( this ); }
	}

	// add to global namespace
	window.PathLoader = PathLoader;

})( window );

function findPosition() {
  return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
}

function distanceFromDocumentTop( element ) {
	var bodyRect = document.body.getBoundingClientRect(),
    	elemRect = element.getBoundingClientRect(),
    	offset   = elemRect.top - bodyRect.top;
	return offset;
}

function lazyLoadImages(load) {
  var imgs = document.querySelectorAll('[data-src]');
  for (var i = 0; i < imgs.length; i++) {
    var self = imgs[i],
      src = self.getAttribute('data-src'),
      top = distanceFromDocumentTop(self),
      inWindow = load ? top >= position && top < (position + windowHeight) : false;

    if( inWindow || !load ) {
      if( !self.classList.contains('loaded') && src.length > 0 ) {
        self.tagName == 'IMG' ? self.setAttribute('src', src) : self.style.backgroundImage = 'url('+src+')';
        self.classList.add('loaded');
      }
    }
  }
}

function loadAsset(url, type, callback, attr) {
  //default to script tag if not specified (supports link/script )
  type = type || 'script';
  attr = attr || {};
  //Find first script tag
  var firstScript = window.document.getElementsByTagName('script')[0],
    tag = window.document.createElement( type ),
    attributes = Object.entries( attr );
    //Check for extra attributes to add
    if ( attributes.length > 0 ) {
      for (const [attribute, value] of attributes) {
        tag.setAttribute(attribute, value);
      }
    }
    //stylesheet settings
    if( type === 'link' ) {
      tag.rel = 'stylesheet'; 
      tag.href = url;
    }

    if( type === 'script' ) {
      tag.src = url;
      tag.async = true;
      tag.defer = true;
    }

  tag.onload = function() {
    typeof callback === 'function' ? callback() : false;
  }

  firstScript.parentNode.insertBefore(tag, firstScript);
  return tag;
}

function throttledScroll() {
  if (throttle) {
    //update position
    position = findPosition();
    // console.log(position);
    throttle = false;
  }
}

//*********************************
// Setup throttled Scroll
//*********************************
position = findPosition();
window.addEventListener('scroll', function() { throttle = true; }, {passive : true});
var scroller = setInterval(throttledScroll, 10);

//*********************************
// Image Lazy Load
//*********************************
//Load only the images that are visible on the screen
lazyLoadImages(true); 
//set a timer for what will likely be after the load finishes
var lazyLoad = setTimeout(function() {
    lazyLoadImages(false);
  }, lazyDelay),
  checkScroll = function() {
    console.log('scroll');
    window.removeEventListener('scroll', checkScroll);
    clearTimeout(lazyLoad);
    lazyLoadImages(false);
  };
//if the user scrolls, initiate loading the rest of the images
window.addEventListener('scroll', checkScroll, {passive : true});

var imgs = document.querySelectorAll('[data-src]'), //All Lazy loading images
  position,
  throttle,
  windowHeight = window.innerHeight,
  frameInPoint = windowHeight - (windowHeight/8), //80%, easyReveal
  trigger = 0.5, // this will be used to calculate ratio of screen, providing location of trigger that activates things
  windowTrigger = windowHeight * trigger,
  lazyDelay = 5000; //delay before loading, lazyLoad, youtube lazyLoad;


loadAsset('lib/fonts/MyFontsWebfontsKit.css', 'link');
// loadAsset('lib/css/main.bundle.css', 'link');

$(document).ready(function() {
  var default_language = 'english';
  apply_language(default_language);



  $('.language-option').click(function(e) {
    e.preventDefault();
    var language = $(this).data('lang');

    var language_videos = {
      english: 'goaszbjD-Y4',
      spanish: 'Pe8ccQogjrs'
    };

    apply_language(language);
    $('#preparation-video').html(
      '<iframe class="" width="" height="417" src="https://www.youtube.com/embed/' +
        language_videos[language] +
        '?rel=0&showinfo=0&controls=2&modestbranding=1" allowfullscreen webkitallowfullscreen mozallowfullscreen> </iframe>'
    );
  });

  setTimeout(function() {
    var hash = window.location.hash;
    if (hash) {
      var clean_hash = hash.slice(1);
      if (clean_hash == 'why-fumigate') {
        navWhyFumigate();
        trackEvent('button', 'click', 'User navigated to Why Fumigate');
      }

      if (clean_hash == 'how-it-works') {
        navHowItWorks();
        trackEvent('button', 'click', 'User navigated to How It Works');
      }

      if (clean_hash == 'how-to-prepare') {
        navHowToPrepare();
        trackEvent('button', 'click', 'User navigated to How To Prepare');
      }
    }
  }, 3000);

  // Initializers
  fullpageConfig();
  parallaxInit();
  // Events
  // Menu Events
  menuToggle.click(function() {
    toggleMenu();
  });
  brand.click(function() {
    setTimeout(navBackToTop, 500);
  });
  $('#menu .Why_Fumigate').click(function() {
    navWhyFumigate();
    trackEvent('button', 'click', 'User navigated to Why Fumigate');
  });
  $('#menu .How_It_Works').click(function(e) {
    navHowItWorks();
    trackEvent('button', 'click', 'User navigated to How It Works');
  });
  $('#menu .How_To_Prepare').click(function(e) {
    navHowToPrepare();
    trackEvent('button', 'click', 'User navigated to How To Prepare');
  });
  // Menu Hover
  // Panel Specific
  //Reveal Slider
  $('.why-fumigate-button').click(function() {
    showRevealSlider();
    trackEvent('button', 'click', 'User revealed Why Fumigate slider');
  });

  $('span.close-reveal-slider').click(function() {
    hideRevealSlider();
    trackEvent('button', 'click', 'User hid Why Fumigate slider');
  });

  $('.hotel-button').click(function() {
    window.open('https://www.google.com/maps/search/hotels');
    trackEvent('button', 'click', 'User navigated to https://www.google.com/maps/search/hotels');
  });

  // $('.learn-more-container').click(function() {
  // 	//window.open("http://vikanegasfumigant.com/");
  // });

  // $('.pdf-download').click(function() {
  // 	//window.open("lib/pdf/PDF_FumigationPlanningGuide.pdf");
  // });

  // Swipe Overrides
  if (screen.width > 767) {
    theSVG.touchwipe({
      wipeUp: function() {
        unclickable();
        touchScrollUp();
      },
      wipeDown: function() {
        unclickable();
        touchScrollDown();
      },
      min_move_x: 20,
      min_move_y: 20,
      preventDefaultEvents: true
    });
  }

  //Hover Menu
  $('.no-touch .menu li').on('mouseover', function() {
    $(this)
      .find('img')
      .addClass('hovered');
  });

  $('.no-touch .menu li').on('mouseout', function() {
    $(this)
      .find('img')
      .removeClass('hovered');
  });

  //Reveal Slider
  $('.reveal-slider').mouseup(function() {
    var pos = $('.cd-handle').position();
    if (pos.left < 200) {
      $('.cd-handle').addClass('goRight');
    } else {
      $('.cd-handle').removeClass('goRight');
    }
  });

  //Compare Hovers: setting hover and tracking hover
  var timer;
  var comparisonHoverEvent = function(name) {
    timer = setTimeout(function(){
      dataLayer.push({'event':'interaction', 'action':'hover', 'label':name});
    }, 1000);
  };
  $('.third li').on('mouseover', function() {
    var name = $(this).text();
    if ($(this).hasClass('c-a')) {
      $('.c-a').addClass('compared');
    }
    if ($(this).hasClass('c-b')) {
      $('.c-b').addClass('compared');
    }
    if ($(this).hasClass('c-c')) {
      $('.c-c').addClass('compared');
    }
    if ($(this).hasClass('c-d')) {
      $('.c-d').addClass('compared');
    }
    if ($(this).hasClass('c-e')) {
      $('.c-e').addClass('compared');
    }
    comparisonHoverEvent(name);
  });

  $('.third li').on('mouseout', function() {
    $('.third li').removeClass('compared');
    timer = clearTimeout(timer);
  });

  // Get Size of BrightCove player
  $(window)
    .on('resize', function() {
      if (screen.width < 768) {
        $('.video').height($('.video object').height());
      }
    })
    .resize();
  // Hide Reveal Slider
  $(document).keyup(function(e) {
    if (e.keyCode === 27) hideRevealSlider();
  });

  $('.cd-image-container').addClass('is-visible');
  function videoObjectHeight() {
    if (screen.width < 768) {
      $('.video').height($('.video object').height());
    }
  }
  setTimeout(videoObjectHeight, 1000);

  $('.brand').addClass('active');

  var previousScroll = 0,
    headerOrgOffset = $('.master').height();

  $(window).on('scroll', function() {
    //console.log('main.js scroll event');
    //TODO put a debounce here - Dirk - 5/7/18
    var currentScroll = $(this).scrollTop();
    if (currentScroll > headerOrgOffset) {
      if (currentScroll > previousScroll) {
        $('.master').addClass('out');
      } else {
        $('.master').removeClass('out');
      }
    }
    previousScroll = currentScroll;
  });

  if (screen.width > 767) {
    window.addEventListener(
      'resize',
      function() {
        playerPauseA();
        playerPauseB();
      },
      false
    );
  }

  // Swipe Overrides
  $('.carousel-inner').touchwipe({
    wipeLeft: function() {
      $('.right.carousel-control').trigger('click');
    },
    wipeRight: function() {
      $('.left.carousel-control').trigger('click');
    },
    min_move_x: 20,
    min_move_y: 20,
    preventDefaultEvents: true
  });

  $(function() {
    $('.carousel').each(function() {
      $(this).carousel({
        interval: false
      });
    });
  });
}); //End Doc Ready

var checker = notFourFour('4.4');

if (checker) {
  $('.android-fix, .the-svg').css('display', 'none');
  $('.not-android').css({ display: 'block', 'max-width': '600px', margin: '0 auto' });
  $('.mobile').css({ display: 'block', 'max-width': '600px', margin: '0 auto' });
  $('.cd-handle span.in-handle').css({ background: "#4e89a0 url('/lib/img/android/bi-carrot.png') no-repeat 5px", 'background-size': '25px' });
  $('.cd-handle.goRight span.in-handle').css({ background: "#4e89a0 url('/lib/img/android/bi-carrot.png') no-repeat 5px", 'background-size': '25px' });
  $('body').addClass('bad-droid');
}

function notFourFour(testversion) {
  // var ua = 'Mozilla/5.0 (Linux; U; Android 4.3.1; en-gb; GT-I9300 Build/IMM76D) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Mobile Safari/534.30';
  // ^^ for testing ^^
  var ua = navigator.userAgent;
  var androidword = ua.indexOf('Android');
  var chromechecker = ua.indexOf('Chrome');
  if (androidword >= 0 && chromechecker == -1) {
    var rest = ua.substring(androidword + 8, ua.length);
    var version = rest.substring(0, rest.indexOf(';'));
    return version < testversion;
  }
}


var site = (function($, undefined) {
  'use strict';

  var self = {},
    debug = true,
    modVP;

  function log(msg) {
    if (debug && console !== undefined && console.log) {
      console.log(msg);
    }
  }

  self.init = function() {
    log('init');

    //brightcove.createExperiences();
    setUpAnalytics();
  };

  function setUpAnalytics() {
    log('setting up analytics');

    $('a').on('click', function(e) {
      //send the click to google analytics
      var $this = $(this),
        action = e.type, //click
        category = 'link',
        label = $this.text().trim(),
        urlChecker = new RegExp('/' + window.location.host + '/ig'),
        href = this.href ? this.href.toLowerCase().trim() : '';

      //clean label text
      //remove extra whitespace and weird characters like >
      label = label
        .replace(/[\s]+/gi, ' ')
        .replace(/[^\w ]/gi, '')
        .trim();

      if ($this.attr('data-toggle')) {
        category = $this.attr('data-toggle').toLowerCase();
      } else if (href.indexOf('.pdf') > 0) {
        category = 'pdf';
      } else if (!urlChecker.test(href)) {
        category = 'external-link';
        label = href;
      }

      log('analytics - cat:' + category + ', action: ' + action + ', label: ' + label);
      if (ga != undefined) {
        ga('send', 'event', category, action, label);
      }
    });
  }
  //set up brightcove tracking events for GA

  // self.onMediaEventFired=function(evt) {
  //        console.log("video MEDIA EVENT: " + evt.type + " fired at position: " + evt.position);
  //        ga('send', 'event', "Video", "Brightcove Video", "play");
  //    };

  // self.myTemplateLoaded=function(experienceID) {
  //         console.log("video loaded");
  //         var player = brightcove.api.getExperience(experienceID);
  //         modVP = player.getModule(brightcove.api.modules.APIModules.VIDEO_PLAYER);
  //     };

  // self.onTemplateReady=function(evt) {
  //        //video ready, set up play event for tracking
  //        modVP.addEventListener(brightcove.api.events.MediaEvent.PLAY, site.onMediaEventFired);
  //    };
  return self;
})(jQuery);
//
//jquery load
jQuery(site.init);

//set up brightcove tracking events for GA
var modVP;
//brightcove.createExperiences();
function onMediaEventFired(evt) {
  //console.log('video MEDIA EVENT: ' + evt.type + ' fired at position: ' + evt.position);
  ga('send', 'event', 'Video', 'Brightcove Video', 'play');
}
function myTemplateLoaded(experienceID) {
  //console.log('video loaded');
  var player = brightcove.api.getExperience(experienceID);
  modVP = player.getModule(brightcove.api.modules.APIModules.VIDEO_PLAYER);
}

function onTemplateReady(evt) {
  //video ready, set up play event for tracking
  modVP.addEventListener(brightcove.api.events.MediaEvent.PLAY, onMediaEventFired);
}

function setChildX() {
  $('.why-fumigate-button .object.text-obj').attr('x', '1');
}
$('.why-fumigate-button.button').on('mouseover', function() {
  setChildX();
});
$('.why-fumigate-button.button').on('mouseout', function() {
  $('.why-fumigate-button .object.text-obj').attr('x', '0');
  setTimeout(setChildX, 1);
});

function apply_language(language) {
  console.log('language: ' + language);
  $('.multi-lang').css('display', 'none');
  $('.' + language).css('display', 'block');
}
